import {
  GDRIVE_API_KEY,
  CLIENT_ID,
  DISCOVERY_URLS,
  SCOPES,
  GOOGLE_API_CLIENT_API_SCRIPT_URL,
} from './constants';

declare const gapi: any;

function addScript() {
  return new Promise(resolve => {
    const script = document.createElement('script');
    script.src = GOOGLE_API_CLIENT_API_SCRIPT_URL;
    script.onload = resolve;
    document.body.appendChild(script);
  });
}

const useGapi = () => {
  const gapiConfig = {
    apiKey: GDRIVE_API_KEY,
    clientId: CLIENT_ID,
    discoveryDocs: DISCOVERY_URLS,
    scope: SCOPES,
  };

  const InitializeAuthUser = async () => {
    await new Promise((res, rej) => {
      (async () => {
        await (window as any).gapi.load('client:auth2', async () => {
          try {
            await (window as any).gapi.client.init(gapiConfig);
            res({ loaded: true });
          } catch (gapiErrorResponse) {
            console.error(gapiErrorResponse);
            rej(new Error(gapiErrorResponse as string));
          }
        });
        return gapi;
      })();
    });
  };

  const init = async () => {
    if ((window as any).gapi?.auth2) {
      return;
    }
    try {
      await addScript();
      return gapi;
    } catch (error) {
      console.error(error as string | Error, 'error');
    }
  };

  const signInWithGoogle = async () => {
    try {
      const GoogleAuth = gapi.auth2.getAuthInstance();

      let currentUser = null;
      await GoogleAuth.signIn().then(
        (res: any) => {
          currentUser = res;
          return res;
        },
        (err: any) => {
          console.error(err);
        },
      );
      return currentUser;
    } catch (error) {
      console.error(error as string | Error, 'error');
    }
  };

  const revokeAccess = async () => {
    await InitializeAuthUser();
    gapi.auth2.getAuthInstance().disconnect();
  };

  const handleAuthorizeDriveModal = (isAuthorized: boolean, user: any) => {
    if (isAuthorized) {
      return handleSignInPrompt(isAuthorized, user);
    }
  };

  const handleGrantedScopes = (currentUser: any, isAuthorized: boolean) => {
    const grantedScopes = currentUser.getGrantedScopes();
    const scopesList = SCOPES.split(' ');
    scopesList.forEach(item => {
      if (!grantedScopes.includes(item)) {
        return handleAuthorizeDriveModal(isAuthorized, currentUser);
      }
    });
    return currentUser;
  };

  const handleDifferentUsers = async (currentUser: any, isAuthorized: boolean): Promise<undefined | boolean | (() => {})> => {
    if (!currentUser) {
      return;
    } else {
        return handleGrantedScopes(currentUser, isAuthorized);
    }

    await revokeAccess();
  };

  const handleSignInPrompt = async (isAuthorized: boolean, user: any) => {
    const currentUser = isAuthorized ? user : await signInWithGoogle();

    if (currentUser) {
      return handleDifferentUsers(currentUser, isAuthorized);
    }
    handleAuthorizeDriveModal(isAuthorized, currentUser);
  };

  const authUser = async (isDriveAuthorized: boolean) => {
    await InitializeAuthUser();
    const GoogleAuth = gapi.auth2?.getAuthInstance();
    if (GoogleAuth) {
      if (GoogleAuth.isSignedIn.get()) {
        return handleSignInPrompt(isDriveAuthorized, GoogleAuth.currentUser.get());
      }
      return handleSignInPrompt(false, {});
    }
  };

  return {
    init,
    authUser,
  };
};

export default useGapi;
