import { GDRIVE_API_KEY } from './constants';

declare const gapi: any;
declare const google: any;
const HOST = "https://mobile-editor-editor.picsartstage2.com";

class PickerWidget {
  static loadPicker() {
    return new Promise((res, rej) => {
      gapi.load('picker', { callback: res, onerror: rej });
    });
  }

  static createPickerFolder = (context: any) => {
    const oauthToken = gapi.auth.getToken().access_token;
    const view = new google.picker.DocsView(google.picker.ViewId.FOLDERS);
    view.setMimeTypes('image/png,image/jpeg,image/jpg');
    view.setSelectFolderEnabled(true);

    const uploadView = new google.picker.DocsUploadView();
    uploadView.setMimeTypes('image/png,image/jpeg,image/jpg');
    uploadView.setIncludeFolders(true);
    uploadView.setParent('');

    // const host = window.location.protocol + '//' + window.location.host;
    console.log('location', HOST, google.script);
    const picker = new google.picker.PickerBuilder()
      .setAppId('')
      .setOAuthToken(oauthToken)
      .setDeveloperKey(GDRIVE_API_KEY)
      .setCallback((data: any) => PickerWidget.createPickerCallback(data, context))
      .setOrigin(HOST) //google.script.host.origin)
      .setLocale('en')
      .addView(view)
      .addView(uploadView)
      .enableFeature(google.picker.Feature.SUPPORT_DRIVES);

    picker.build().setVisible(true);
  }

  static async createPickerCallback(data: any, context: any) {
    if (data.action === google.picker.Action.PICKED) {
      const imgUrl = JSON.stringify(data, null, 2);
    //   document.getElementById('content').innerText = "Loading...";
      console.log('imgUr', imgUrl);
      const doc = data.docs[0];
      const response = await gapi.client.drive.files.get({
        fileId: doc.id,
        alt: 'media',
      })
      const blobObj = fetch(`data:${response.headers['Content-Type']};base64, ${btoa(response.body)}`).then(res => res.blob());
      const src = await blobObj.then(blobData => URL.createObjectURL(blobData));
      console.log('src', src);

      context.handlers.select([{ mimeType:response.headers['Content-Type'], data: btoa(response.body) }]);
      console.log('context', context);
      return context;

      // document.getElementById('content').innerHTML = imgUrl;
    //   document.getElementById('img')?.setAttribute('src', src);
    //   document.getElementById('content').innerText = "";
    }
  }

  static async createPicker(callback: () => {}) {
    const oauthToken = gapi.auth.getToken().access_token;

    const myDriveView = new google.picker.DocsView(google.picker.ViewId.FOLDERS)
      .setOwnedByMe(true)
      .setIncludeFolders(true)
      .setSelectFolderEnabled(true);

    const picker = new google.picker.PickerBuilder()
      .addView(myDriveView)
      .setOAuthToken(oauthToken)
      .setDeveloperKey(GDRIVE_API_KEY)
      .setCallback((data: any) => PickerWidget.pickerCallback(data, callback))
      .setOrigin(HOST)
      .enableFeature(google.picker.Feature.SUPPORT_DRIVES)
      .setTitle('Pick a folder')
      .build();
    picker.setVisible(true);
  }

  static async pickerCallback(data: any, callback: (folderId: string) => {}) {
    if (data[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
      const folder = data[google.picker.Response.DOCUMENTS][0];
      callback(folder.id);
    }
  }

  async init(context: any) {
    await PickerWidget.loadPicker();
    // await PickerWidget.createPicker(callback);
    await PickerWidget.createPickerFolder(context);
  }
}

const PickerFolder: any = new PickerWidget();

export default PickerFolder;
