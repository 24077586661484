// scripts
export const GOOGLE_API_CLIENT_API_SCRIPT_URL = 'https://apis.google.com/js/api.js';

// URLs
export const DRIVE_MULTIPART_UPLOAD_URL = 'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart';

// configs for local
export const GDRIVE_API_KEY = 'AIzaSyDy6SRTXKsQx4TlXzxWw1LcN7OwiwwgtQM';
export const CLIENT_ID = '797908848155-tvpt1osh8iabmksl8rmj06vifo8eli88.apps.googleusercontent.com';

// configs for server
// export const GDRIVE_API_KEY = 'AIzaSyAosb4GOqNqt8pzXADLqNG2mU4zireIn7Q';
// export const CLIENT_ID = '176762466840-5sb246b1kkci5mar81gqhp4ra5g3r3rr.apps.googleusercontent.com';

const DRIVE_API_DISCOVERY_DOCUMENT = 'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest';

export const DISCOVERY_URLS = [
  DRIVE_API_DISCOVERY_DOCUMENT,
];
export const DRIVE_API_SCOPES = {
  recommended: {
    appdata: 'https://www.googleapis.com/auth/drive.appdata',
    file: 'https://www.googleapis.com/auth/drive.file',
    install: 'https://www.googleapis.com/auth/drive.install',
    drive: 'https://www.googleapis.com/auth/drive.readonly',
  },
};

export const SCOPES = [
  DRIVE_API_SCOPES.recommended.file,
  DRIVE_API_SCOPES.recommended.install,
  DRIVE_API_SCOPES.recommended.appdata,
  DRIVE_API_SCOPES.recommended.drive,
].join(' ');
